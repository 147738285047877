<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 44"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0105 3.74551C13.2416 1.52718 16.9156 1.92135 18.7838 4.41835L21.0975 7.50568C22.6191 9.53701 22.4835 12.375 20.6776 14.1698L20.2413 14.6062C20.192 14.7894 20.187 14.9817 20.2266 15.1672C20.3421 15.9152 20.9673 17.4992 23.5853 20.1025C26.2033 22.7058 27.7983 23.3292 28.5573 23.4465C28.7484 23.4871 28.9464 23.4815 29.1348 23.43L29.8828 22.6857C31.4888 21.0907 33.9528 20.7918 35.9401 21.8717L39.4418 23.7783C42.443 25.4063 43.2001 29.4837 40.7435 31.9275L38.1383 34.5162C37.317 35.332 36.2133 36.0122 34.8676 36.1387C31.5493 36.4485 23.8181 36.0525 15.691 27.973C8.10647 20.4307 6.6508 13.8527 6.46563 10.6113C6.37397 8.97235 7.14763 7.58635 8.13397 6.60735L11.0105 3.74551ZM16.5838 6.06651C15.6543 4.82535 13.9236 4.72635 12.9483 5.69618L10.07 8.55618C9.46497 9.15751 9.1753 9.82118 9.21197 10.4555C9.35863 13.0313 10.532 18.9658 17.6306 26.0242C25.0776 33.4272 31.9545 33.649 34.6128 33.3997C35.1555 33.3502 35.6945 33.0679 36.1986 32.5674L38.802 29.9768C39.8616 28.9245 39.6288 27.0068 38.1291 26.1928L34.6275 24.288C33.6595 23.7637 32.5265 23.936 31.8225 24.6363L30.9883 25.4668L30.0166 24.4915C30.9883 25.4668 30.9865 25.4687 30.9846 25.4687L30.9828 25.4723L30.9773 25.4778L30.9645 25.4888L30.937 25.5145C30.859 25.5857 30.7757 25.6506 30.6876 25.7088C30.541 25.806 30.3466 25.9142 30.1028 26.004C29.6078 26.1892 28.9515 26.2882 28.1411 26.1635C26.5516 25.9197 24.4451 24.8362 21.6456 22.0532C18.848 19.2702 17.7553 17.1765 17.5096 15.5888C17.3831 14.7785 17.484 14.1222 17.671 13.6272C17.7747 13.349 17.9219 13.0891 18.1073 12.8572L18.166 12.793L18.1916 12.7655L18.2026 12.7545L18.2081 12.749L18.2118 12.7453L18.7398 12.221C19.5245 11.4382 19.6345 10.142 18.8956 9.15385L16.5838 6.06651Z"
    />
  </svg>
</template>
